var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('project-header',{attrs:{"project":_vm.project}}),_c('div',{attrs:{"id":"project-content"}},[_c('div',{staticClass:"project-text",domProps:{"innerHTML":_vm._s(_vm.project.text)}}),_vm._l((_vm.project.imgs),function(img,index){return _c('div',{key:index,staticClass:"project-image",class:img.type},[(img.url || img.type === 'screen' || img.type === 'gif')?_c('a',{attrs:{"target":"_blank","href":_vm.project.url}},[_c('img',{attrs:{"src":_vm.getImgUrl(_vm.project.id + '/' + img.name)}})]):_c('img',{attrs:{"src":_vm.getImgUrl(_vm.project.id + '/' + img.name)}}),(img.leg)?_c('p',{staticClass:"leg"},[_vm._v(_vm._s(img.leg))]):_vm._e()])})],2),_c('div',{attrs:{"id":"project-footer"}},[_c('div',[_c('router-link',{key:_vm.$route.fullPath,attrs:{"to":{
          name: 'Projet',
          params: {
            id: _vm.prev().id,
            nom: _vm.prev().name,
          },
        }}},[_vm._v(" ← "+_vm._s(_vm.prev().name)+" ")])],1),_c('div',[_c('router-link',{attrs:{"to":{
          name: 'Projet',
          params: {
            id: _vm.next().id,
            nom: _vm.next().name,
          },
        }}},[_vm._v(" "+_vm._s(_vm.next().name)+" → ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }