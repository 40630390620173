<template>
  <div
    id="project-header"
    :style="'background-color: ' + project.bgcolor + ';'"
  >
    <div id="target"></div>
    <div id="header-content">
      <p class="project-infos">
        <span :class="project.journal ? 'journal' : ''">{{
          project.context
        }}</span
        >, {{ project.date }}
      </p>
      <h2 id="header-title">{{ project.name }}</h2>
      <project-tags
        id="header-tags"
        :project="project"
        :color="project.bgcolor"
        :direction="'row'"
      />
      <a id="header-link" class="more" target="_blank" :href="project.url"
        >voir le projet</a
      >
      <a id="header-link-small" target="_blank" :href="project.url">{{ project.name }} →</a>
    </div>
  </div>
</template>

<script>
import ProjectTags from "@/components/ProjectTags.vue";

export default {
  name: "ProjectHeader",
  props: ["project"],
  components: {
    ProjectTags,
  },
  created() {
    window.addEventListener("wheel", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("wheel", this.handleScroll);
  },
  mounted() {
    if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        document.getElementById("project-header").classList.remove("small");
        document.getElementById("project-header").style.position = "relative";
        document.getElementById("project-content").style.marginTop = "60px";
    }
  },
  methods: {
    getImgUrl(nom) {
      return require("../assets/" + nom);
    },
    handleScroll(event) {
    if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return;
    }
      let header = document.getElementById("project-header");
      if (window.innerWidth < 700) {
        header.classList.remove("small");
        document.getElementById("project-content").style.marginTop = "60px";
        return;
      }
      if (
        document.documentElement.scrollTop >
        document.getElementById("target").offsetTop
      ) {
        if (!header.classList.contains("small")) {
          if (event.deltaY > 0) {
            document.documentElement.scrollTo(0, 0);
            header.classList.add("small");
            document.getElementById("project-content").style.marginTop =
              170 + "px";
          }
        } else {
          return;
        }
      } else {
        if (event.deltaY < 0) {
          header.classList.remove("small");
          document.getElementById("project-content").style.marginTop =
            "calc(50vh + 100px)";
        }
      }
    },
  },
};
</script>

<style scoped>
#target {
  position: fixed;
  top: 0px;
}

#project-header {
  width: 100vw;
  display: flex;
  align-items: center;
  transition: min-height 200ms;
  position: relative;
  top: 0;
  min-height: 50vh;
}

@media (min-width: 700px) {
  #project-header {
    position: fixed;
  }
}

#project-header.small {
  min-height: 0;
  transition: min-height 600ms;
}

#header-content {
  width: 80vw;
  max-width: 740px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
}

#project-header.small #header-content {
  padding-top: 1em;
}

#header-content h2 {
  font-weight: 200;
  font-size: 3em;
  display: block;
}

.project-infos {
  margin-bottom: 0.2em;
}

.project-tags {
  margin-top: 1.5em;
  justify-content: center;
}

#project-header.small #header-title,
#project-header.small .project-infos,
#project-header.small .project-tags {
  display: none;
}

.more {
  position: relative;
  display: inline-block;
  font-size: 1.6em;
  font-family: temeraire;
  font-style: italic;
  font-weight: 600;
  transition: all 200ms;
  margin: 1.5em 0;
  border-bottom: 2px solid var(--default);
}

.more:hover {
  padding-right: 2em;
}

.more::after {
  content: "→";
  position: absolute;
  right: 0.2em;
  opacity: 0;
  transition: opacity 400ms;
  font-style: initial;
}

.more:hover::after {
  opacity: 1;
}

#project-header.small #header-content {
  display: flex;
  justify-content: center;
}

#project-header.small #header-link {
  display: none;
}

#header-link-small {
  display: none;
}

#project-header.small #header-link-small {
  display: inline-block;
  font-style: initial;
  font-size: 1.6em;
  margin: 0;
  margin-left: 1em;
  padding-bottom: 0.6em;
  border-bottom: 0px solid var(--default);
  transition: opacity 200ms;
}

#project-header.small #header-link-small:hover {
  opacity: 0.4;
}

.journal {
  font-style: italic;
}

@media (max-width: 900px) {
  #project-header.small #header-title {
    text-align: center;
  }

  #header-content h2 {
    font-size: 2.3em;
  }

  .more {
    font-size: 1.4em;
  }
}
</style>
