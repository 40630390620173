<template>
  <div class="about">
    <div id="close-about">
      <a @click="hasHistory() ? $router.go(-1) : $router.push('/')">
        <img :src="getImgUrl('close.svg')" />
      </a>
    </div>
    <div id="about-content">
      <div id="about-presentation">
        <div id="about-text">
          <div id="hello">
            <img :src="getImgUrl('helloblanc.png')" />
          </div>
          <p>
            Étudiante en troisième année de Bachelor Développement interactif à
            Gobelins, je suis passionnée par la visualisation de données. J'aime
            les cartes, les listes et coder en écoutant du lo-fi.
          </p>
          <p>
            Je partage des dessins sur
            <a href="https://www.instagram.com/elsadlms/" target="_blank"
              >instagram</a
            >
            et je m'inspire sur
            <a href="https://www.pinterest.fr/elsa_/design/" target="_blank"
              >pinterest</a
            >.
          </p>
          <p id="cv">
            <a target="_blank" href="/CV_Elsa_Delmas.pdf">Voir mon CV →</a>
          </p>
          <!--
          <p>
            <span class="big">On s'écrit ?</span><br />
            <a href="mailto:hello@elsadelmas.fr"
              >hello@elsadelmas.fr</a
            >
            /
            <a
              href="https://www.linkedin.com/in/elsa-delmas/"
              target="_blank"
              >linkedin</a
            >
            /
            <a
              href="https://twitter.com/elsadlms"
              target="_blank"
              >twitter</a
            >
          </p>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {
    getImgUrl(nom) {
      return require("../assets/" + nom)
    },
    hasHistory() {
      return window.history.length > 2
    },
  },
}
</script>

<style>
.about {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #131313;
  color: white;
}

#close-about {
  position: absolute;
  top: 2em;
  left: 2em;
  width: 30px;
  transition: opacity 200ms;
  cursor: pointer;
  z-index: 100;
}

#close-about:hover {
  opacity: 0.4;
}

#about-content {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -5%);
  height: 100vh;
  width: 85vw;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#about-text #hello {
  width: 180px;
  margin-left: 0px;
  margin-bottom: 40px;
}

@media (min-width: 600px) {
  #about-content {
    width: 70vw;
  }
}

@media (min-width: 800px) {
  #about-content {
    width: 50vw;
  }
  #about-text #hello {
    width: 240px;
    margin-left: -80px;
    margin-bottom: 0px;
  }
}

#about-text p {
  font-size: 1.2em;
  font-family: "stratos-lights";
}

#about-text p + p {
  margin-top: 1em;
}

#about-text a {
  color: white;
  transition: opacity 400ms;
  position: relative;
  display: inline-block;
}

#about-text a::after {
  content: "";
  display: block;
  height: 2px;
  background-color: white;
  width: 100%;
  transition: all 400ms;
}

#about-text a:hover::after {
  width: 0%;
  opacity: 0.4;
}

#about-text a:hover {
  opacity: 0.4;
}
</style>
