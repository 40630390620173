<template>
  <div class="project">
    <project-header :project="project" />
    <div id="project-content">
      <div class="project-text" v-html="project.text"></div>
      <div
        class="project-image"
        :class="img.type"
        v-for="(img, index) in project.imgs"
        :key="index"
      >
        <a
          v-if="img.url || img.type === 'screen' || img.type === 'gif'"
          target="_blank"
          :href="project.url"
        >
          <img :src="getImgUrl(project.id + '/' + img.name)" />
        </a>
        <img v-else :src="getImgUrl(project.id + '/' + img.name)" />
        <p class="leg" v-if="img.leg">{{ img.leg }}</p>
      </div>
    </div>
    <div id="project-footer">
      <div>
        <router-link
          :key="$route.fullPath"
          :to="{
            name: 'Projet',
            params: {
              id: prev().id,
              nom: prev().name,
            },
          }"
        >
          ← {{ prev().name }}
        </router-link>
      </div>
      <div>
        <router-link
          :to="{
            name: 'Projet',
            params: {
              id: next().id,
              nom: next().name,
            },
          }"
        >
          {{ next().name }} →
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectHeader from "@/components/ProjectHeader.vue"
import projectsData from "@/projects.json"

export default {
  name: "Project",
  components: {
    ProjectHeader,
  },
  methods: {
    getImgUrl(nom) {
      return require("../assets/" + nom)
    },
    prev() {
      let prev = projectsData.projects[this.index - 1]
      if (this.index == 0) {
        prev = projectsData.projects[projectsData.projects.length - 1]
      }
      return prev
    },
    next() {
      let next = projectsData.projects[this.index + 1]
      if (this.index == projectsData.projects.length - 1) {
        next = projectsData.projects[0]
      }
      return next
    },
  },
  props: ["id"],
  data() {
    let urlID = this.id
    let projectIndex
    let project = projectsData.projects.filter(function(x, i) {
      if (x.id === urlID) {
        projectIndex = i
      }
      return x.id === urlID
    })
    return {
      project: project[0],
      index: projectIndex,
    }
  },
}
</script>

<style>
#project-content {
  margin: 0 auto;
  width: 90vw;
  max-width: 1000px;
  margin-top: 60px;
  transition: all 600ms;
}

#project-header .project-tag {
  background-color: #ffffff4d;
}

#project-content .project-text {
  max-width: 700px;
  margin: 0 auto;
}

#project-content .project-text a {
  border-bottom: 1px solid;
}

#project-content p {
  margin-bottom: 1.6em;
  font-size: 1.1em;
  line-height: 1.8em;
}

#project-content .leg {
  font-size: 1rem;
  font-style: italic;
  color: #9e9e9e;
  font-weight: 200;
  margin-top: 0.6em;
}

.project-image {
  margin-top: 70px;
  font-size: 0;
}

.project-image:last-of-type {
  margin-bottom: 100px;
}

.project-image.carte {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px;
}

.project-image.poster {
  box-shadow: 0 4px 30px #67655d1a, 0 6px 10px #67655d1a;
  max-width: 600px;
  margin: 100px auto;
}

.project-image.carte:last-of-type {
  margin-bottom: 100px;
}

@media (min-width: 700px) {
  .project-image:last-of-type {
    margin-bottom: 200px;
  }

  .project-image.poster {
    margin-bottom: 200px;
  }

  .project-image.screen,
  .project-image.gif {
    display: block;
    border: 3px #eee solid;
    border-top: #eee 26px solid;
    border-radius: 6px;
    font-size: 0;
  }

  #project-content {
    width: 80vw;
    margin-top: calc(50vh + 100px);
  }
}

#project-footer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  text-align: center;
  background-color: #efefef;
  padding: 6em 2em;
  font-size: 1.2em;
  color: #7d7d7d;
}

#project-footer a {
  font-family: stratos-lights;
  display: inline-block;
  color: #a9a9a9;
  position: relative;
  transition: opacity 600ms;
}

#project-footer a:hover {
  opacity: 0.4;
}

@media (min-width: 1000px) {
  #project-footer a::before,
  #project-footer a::after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 52%;
    height: 2px;
    background-color: #a9a9a9;
    transition: width 600ms ease-in-out;
  }

  #project-footer a::after {
    right: 0;
  }

  #project-footer a:hover::before,
  #project-footer a:hover::after {
    width: 0;
  }

  #project-footer a:hover {
    opacity: 0.6;
  }
}

@media (max-width: 700px) {
  #project-footer {
    grid-template-columns: 1fr;
    grid-gap: 2em;
    padding: 3em 2em;
  }
}
</style>
